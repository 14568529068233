import React, { ReactNode } from "react";
import { Constants, spacing, styled } from "src/styles";

export type SplitBlockProps = {
    left: ReactNode;
    right: ReactNode;
};
export default function SplitBlock(props: SplitBlockProps) {
    const { left, right } = props;
    return (
        <Wrapper>
            <Left>{left}</Left>
            <Right>{right}</Right>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;

    @media screen and (max-width: ${Constants.MEDIA_CHANGE_TABLET}px) {
        flex-direction: column-reverse;
        align-items: center;
    }
`;

const Left = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    position: relative;
    max-width: 100%;
`;

const Right = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    position: relative;
    max-width: 100%;
`;

const Image = styled.div`
    display: inline-block;
    position: relative;
    width: 70%;
    margin: 0 auto;
    aspect-ratio: 0.8;
    overflow: hidden;
    max-width: 100%;
    z-index: 15;

    @media screen and (max-width: ${Constants.MEDIA_CHANGE_TABLET}px) {
        margin: ${spacing("xl", 1)}px auto;
        width: 100%;
        height: 450px;
    }
`;

const Body = styled.div`
    width: 90%;
    max-width: 450px;
    position: relative;
    padding: 0 18px;
    margin: 0 auto;
`;

const Header = styled.h2``;

const Paragraph = styled.p``;

SplitBlock.Image = Image;
SplitBlock.Body = Body;
SplitBlock.Header = Header;
SplitBlock.Paragraph = Paragraph;
