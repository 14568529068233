import Image from "next/image";
import React, { ReactNode } from "react";
import { Colours, Constants, spacing, styled } from "src/styles";

export type BannerNewProps = {
    title?: ReactNode;
    body?: ReactNode;
    actions?: ReactNode;
    image?: string;
};

export default function Banner(props: BannerNewProps) {
    const { title, body, actions, image } = props;

    return (
        <>
            <Wrapper>
                <Inner>
                    <InnerInner>
                        <Left>
                            {title && <Title>{title}</Title>}
                            {body && <Bodyy>{body}</Bodyy>}
                            {actions && <Actions>{actions}</Actions>}
                        </Left>
                        <Right>
                            <MainImage>
                                <Image src={image} layout={"fill"} quality={100} objectPosition={"center"} objectFit={"cover"} />
                            </MainImage>
                        </Right>
                    </InnerInner>
                </Inner>
                <Background />
            </Wrapper>
        </>
    );
}

const Spacer = styled.div<{height: number}>`
    width: 1px;
    height: ${(p) => p.height}px;
`;

const Wrapper = styled.div`
    font-family: "Kumbh Sans", sans-serif;
    color: ${Colours.GREY_TEXT};
    font-size: 18px;
    line-height: 1.667em;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    padding-top: 80px;
    padding-bottom: 80px;

    @media screen and (max-width: ${Constants.MEDIA_CHANGE_MOBILE}px) {
        padding-top: 0;
        padding-bottom: 0;
    }
`;

const Left = styled.div`
    position: relative;
    padding: ${spacing("xl")}px;
    width: 100%;
`;

const Right = styled.div`
    position: relative;
    padding: ${spacing("xl")}px;
    width: 100%;
`;

const BlockInner = styled.div`
    //width: 100%;
    //margin: 0 auto;
`;

const Inner = styled.div`
    color: ${Colours.GREY_TEXT};
    font-size: 18px;
    line-height: 1.667em;
    box-sizing: border-box;
    max-width: 1209px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 24px;
    padding-left: 24px;
    -webkit-box-flex: 1;
    flex: 1;
`;

const InnerInner = styled.div`
    font-family: "Kumbh Sans", sans-serif;
    color: #62778f;
    font-size: 18px;
    line-height: 1.667em;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media screen and (max-width: ${Constants.MEDIA_CHANGE_TABLET}px) {
        flex-direction: column;
        text-align: center;
    }
`;

const Background = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
    width: 39%;
    background-color: #064ea4;

    @media screen and (max-width: ${Constants.MEDIA_CHANGE_TABLET}px) {
        left: 0;
        top: auto;
        width: 100%;
        min-height: 50%;
    }

    @media screen and (max-width: ${Constants.MEDIA_CHANGE_TABLET}px) {
        min-height: 30%;
    }
`;

const MainImage = styled.div`
    position: relative;
    width: 450px;
    aspect-ratio: 0.85;
    max-width: 100%;
    overflow: hidden;
    margin: 0 auto;

    @media screen and (max-width: ${Constants.MEDIA_CHANGE_TABLET}px) {
        width: 366px;
    }
`;

const Title = styled.h1`
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    opacity: 1;
    margin-bottom: 0;
`;

const Bodyy = styled.div`
    font-family: "Kumbh Sans", sans-serif;
    color: #62778f;
    font-size: 18px;
    line-height: 1.667em;
    box-sizing: border-box;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    opacity: 1;
`;

const Actions = styled.div`
    display: flex;
    flex-direction: row;
    padding-top: 45px;

    @media screen and (max-width: ${Constants.MEDIA_CHANGE_TABLET}px) {
        justify-content: center;
    }
`;
