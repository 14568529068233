import { NextSeo } from "next-seo";
import Image from "next/image";
import React from "react";
import SplitBlock from "src/components/widgets/splitBlock/SplitBlock";
import Banner from "../components/widgets/bannerNew/Banner";
import Button from "../components/widgets/button/Button";
import Section from "../components/widgets/section/Section";
import sanity from "../sanity/sanity";
import Newsletter from "../sanity/types/Newsletter";
import { Colours, spacing, styled } from "../styles";
import styles from "./Home.module.scss";

export type HomeProps = {
    newsletters: Newsletter[];
};

export default function Home(props: HomeProps) {
    const { newsletters } = props;

    return (
        <>
            <NextSeo
                openGraph={{
                    images: [
                        {
                            url: "https://cdn.sanity.io/images/4f1fpc2v/production/c53ad4e907064d6938efb7480af9d2b3580b9f6a-1500x1001.jpg",
                        },
                    ],
                }}
            />
            <Banner
                title={"We are dedicated in providing you with top quality tuition."}
                image={"https://cdn.sanity.io/images/4f1fpc2v/production/c53ad4e907064d6938efb7480af9d2b3580b9f6a-1500x1001.jpg"}
                actions={
                    <>
                        <Button href={"/classes"}>Explore classes</Button>
                    </>
                }
            />
            <Section>
                <Section.LeftBackground />
                <SplitBlock
                    left={
                        <SplitBlock.Image>
                            <Image src={"/images/classes/tasman_atlantic_pacific_mix/48.jpg"} layout={"fill"} objectFit={"cover"} />
                        </SplitBlock.Image>
                    }
                    right={
                        <SplitBlock.Body>
                            <SplitBlock.Header>Who we are</SplitBlock.Header>
                            <SplitBlock.Paragraph>The Ross Millar Swim School has been operating since 1990.</SplitBlock.Paragraph>
                            <SplitBlock.Paragraph>
                                Ross, Jennimay, our son Jordan and our amazing instructors are dedicated to providing the best quality
                                tuition in a safe and fun environment.{" "}
                            </SplitBlock.Paragraph>
                            <SplitBlock.Paragraph>
                                Our focus is to ensure we provide a well-balanced program that allows all students to have the
                                opportunity to build water confidence, a love of the water and to be extended in both technique and
                                stamina.
                            </SplitBlock.Paragraph>
                            <SplitBlock.Paragraph>
                                We are proud to have so many parents recommending our swim school to their friends and families and to
                                have the pleasure of teaching the next generations over the past 31 years
                            </SplitBlock.Paragraph>
                        </SplitBlock.Body>
                    }
                />
            </Section>
            <TickSection textColour={Colours.WHITE} backgroundColour={Colours.BLUE_DARK} center>
                <h2>Proudly teaching aquatic skills to the community since 1990</h2>

                <div className={styles.tickBlocks}>
                    <div className={styles.tickBlock}>
                        <div className={styles.tickCircle}>
                            <Image src={"/images/icon_tick_circle_small.svg"} layout={"fill"} objectFit={"cover"} />
                        </div>
                        <h4 className={styles.tickBlockText}>Lessons for all ages</h4>
                    </div>
                    <div className={styles.tickBlock}>
                        <div className={styles.tickCircle}>
                            <Image src={"/images/icon_tick_circle_small.svg"} layout={"fill"} objectFit={"cover"} />
                        </div>
                        <h4 className={styles.tickBlockText}>Booster classes available in the school holidays</h4>
                    </div>
                    <div className={styles.tickBlock}>
                        <div className={styles.tickCircle}>
                            <Image src={"/images/icon_tick_circle_small.svg"} layout={"fill"} objectFit={"cover"} />
                        </div>
                        <h4 className={styles.tickBlockText}>Private lessons available</h4>
                    </div>
                </div>
            </TickSection>
            <Section>
                <Section.RightBackground />
                <SplitBlock
                    right={
                        <SplitBlock.Image>
                            <Image src={"/images/classes/breather/30.jpg"} layout={"fill"} objectFit={"cover"} />
                        </SplitBlock.Image>
                    }
                    left={
                        <SplitBlock.Body>
                            <SplitBlock.Header>What we offer</SplitBlock.Header>
                            <SplitBlock.Paragraph>
                                Dedicated swimming tuition for babies, pre-schoolers, school age students, squads and adults.
                            </SplitBlock.Paragraph>
                            <SplitBlock.Paragraph>
                                We run four term based courses throughout the year. This allows students to keep continuity of
                                technique and fitness while being able to partake of other extracurricular activities.{" "}
                            </SplitBlock.Paragraph>
                            <SplitBlock.Paragraph>
                                During the school holidays we run booster classes in January, April and October. These are a great
                                option for quick progress in a short time as students come each day for 5 days, 10 days or 15 days
                                (January only 3 week option). Holiday courses are particularly beneficial when a student is nervous in
                                the water, is working on breathing technique, or looking to increase speed and stamina.
                            </SplitBlock.Paragraph>
                            <SplitBlock.Paragraph>
                                The pool is set at 32 degrees and is indoor. We are not open to the public for casual swimming so we
                                are able to offer a dedicated teaching environment.
                            </SplitBlock.Paragraph>
                        </SplitBlock.Body>
                    }
                />
            </Section>
            <Section backgroundColour={Colours.BLUE} textColour={Colours.WHITE} center>
                <h2>Swim School Newsletters</h2>
                <h4>Our latest term newsletter is available to download now</h4>
                {newsletters.map(({ _id, name, fileUrl }) => {
                    return (
                        <a key={_id} href={fileUrl} target={"_blank"} rel={"noreferrer noopener"}>{name}</a>
                    );
                })}
            </Section>
        </>
    );
}

const TickSection = styled(Section)`
    > div {
        margin: auto;
        padding: ${spacing("l")}px;
        padding-top: ${spacing("xl", 4)}px;
    }
`;

export async function getStaticProps(): Promise<any> {
    const newsletters = await sanity.getNewsletters();
    return {
        props: {
            newsletters,
        },
        revalidate: 60,
    };
}
